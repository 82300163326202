import React, { useState } from 'react';
import * as xlsx from "xlsx";
import * as _ from "lodash";
import 'react-calendar/dist/Calendar.css';

import './App.css';

var representatives;
var activeEmployees;
var visitStatus;
var placeVisits;

const readUploadFile = (e) => {
  e.preventDefault();
  return new Promise((resolve, reject) => {
    try {
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, {
            type: "array"
          });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet, {
            defval: null
          });
          resolve(json);
        }
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    } catch (error) {
      reject(Error(error));
    }
  });
}

const downloadExcel = (data, fileName, format) => {

  const worksheet = xlsx.utils.json_to_sheet(data);
  const workbook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binar y" });

  let bookType = format || "xlsx"

  xlsx.writeFile(workbook, fileName + "." + bookType, { bookType: bookType });
}

const dateFormatter = (dateToFormat) => {
  return (dateToFormat.getMonth() + 1) + '/' + dateToFormat.getDate() + '/' + dateToFormat.getFullYear();
}

const timeFormatter = (dateToFormat) => {
  return String(dateToFormat.getHours()).padStart(2, "0") + ':' + String(dateToFormat.getMinutes()).padStart(2, "0");
}

const getDaysArray = (s, e) => {
  for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) {
    a.push(new Date(d));
  }
  return a;
}

const dateDiffToString = (a, b) => {
  let diff = Math.abs(a - b);

  let ms = diff % 1000;
  diff = (diff - ms) / 1000;
  let s = diff % 60;
  diff = (diff - s) / 60;
  let m = diff % 60;
  diff = (diff - m) / 60;
  let h = diff;

  let ss = s <= 9 && s >= 0 ? `0${s}` : s;
  let mm = m <= 9 && m >= 0 ? `0${m}` : m;
  let hh = h <= 9 && h >= 0 ? `0${h}` : h;

  return hh + ':' + mm;
};

function sumTime(array = []) {

  let factors = [3600, 60, 1];
  let seconds = array.reduce((seconds, time) => time.split(':').reduce((s, t, i) => s + t * factors[i], seconds), 0);
  return factors.map(factor => {
    const value = Math.floor(seconds / factor);
    seconds -= value * factor;
    return value.toString().padStart(2, 0);
  }).join(':');
}

const prepareChart = (allowAnyDD) => {

  let payrollChart = [];

  let firstDate = _.orderBy(placeVisits, ["Date"])[0]["Date"];
  let lastDate = _.orderBy(placeVisits, ["Date"], "desc")[0]["Date"];

  const _days = getDaysArray(firstDate, lastDate);

  _.forEach(activeEmployees, employee => {

    let dayBlock = {};

    _.forEach(_days, day => {

      let dayFormatted = dateFormatter(day);

      let sortedActivities = _.sortBy(placeVisits, ["Date"], "asc");

      _.forEach(sortedActivities, activity => {
        let _rep = _.find(representatives, { "ID": activity["Representative ID"] });
        if (_rep && _rep != null) {
          activity["Paychex ID"] = _.trim(_rep["Paychex ID"])
        }
      });

      let employeesDay = _.filter(sortedActivities, a => {

        //let _rep = _.find(representatives, { "ID": a["Representative ID"] });

        let representativeId = _.trim(a["Paychex ID"] || a["Representative ID"]);

        let _formattedDate = dateFormatter(new Date(a["Date"]));

        if (representativeId == employee["EE ID"] && _formattedDate == dayFormatted) {
          return true;
        }
      }
      );

      if (employeesDay != null && employeesDay.length > 0) {
        //debugger;


        //let employeesOfficeCheckIn = _.filter(employeesDay, { "Tags": "LMUS-OFFICE" });

        let employeesOfficeCheckIn = _.filter(employeesDay, function (day) {
          return allowAnyDD ? (_.startsWith(day["Place ID"], "DD-") || day["Tags"] === "LMUS-OFFICE") : day["Tags"] === "LMUS-OFFICE"
        });


        let employeesOtherCheckins = _.filter(employeesDay, (v) => _.reject(employeesOfficeCheckIn, { "Place ID": v["Place ID"] })) // assign it to some variable

        //let employeesOtherCheckins = _.reject(employeesDay, { "Tags": "LMUS-OFFICE" });

        if ((employeesOfficeCheckIn != null && employeesOfficeCheckIn.length > 0)) {
          //debugger;
          let firstCheckinAtOffice = new Date(_.last(employeesOfficeCheckIn)["Start time"]);
          let lastCheckinAtOffice = new Date(_.first(employeesOfficeCheckIn)["End time"] || _.last(employeesOfficeCheckIn)["Start time"]);

          const diff = lastCheckinAtOffice - firstCheckinAtOffice;

          var hours = dateDiffToString(firstCheckinAtOffice, lastCheckinAtOffice);

          dayBlock[dayFormatted + " - IN"] = timeFormatter(firstCheckinAtOffice);
          dayBlock[dayFormatted + " - OUT"] = timeFormatter(lastCheckinAtOffice);
          dayBlock[dayFormatted + " - Total"] = hours;
        }

        if ((employeesOtherCheckins != null && employeesOtherCheckins.length > 0)) {
          dayBlock[dayFormatted + " - Place Visit"] = employeesOtherCheckins.length;
        }

      }
    })

    let firstBlock = {
      "EE ID": employee["EE ID"],
      "Full Name": employee["Full Name"],
      "Position": employee["Position"],
      "Location": employee["Location"],
      "Territory": employee["Territory"],
      "Total Hrs": ""
    };


    let payrollChartRow = { ...firstBlock, ...dayBlock };

    //debugger;
    let hours = [];
    for (const [key, value] of Object.entries(dayBlock)) {
      if (key.includes("- Total")) {

        if (value && value != NaN) {
          hours.push(value);
        }
      }
    }
    if (hours && hours.length > 0) {
      payrollChartRow["Total Hrs"] = sumTime(hours);
    }

    payrollChart.push(payrollChartRow)
  });


  let sortedPayrollChart = _.sortBy(payrollChart, ["Territory", "Location", "Position", "Full Name"]);

  console.log("sortedPayrollChart", sortedPayrollChart);
  downloadExcel(sortedPayrollChart, "Payroll");
}

const prepareChartBySchedules = () => {
  debugger;
  let payrollChart = [];

  let firstDate = _.sortBy(visitStatus, "Date", "asc")[0]["Date"];
  let lastDate = _.sortBy(visitStatus, "Date", "desc")[0]["Date"];

  const _days = getDaysArray(firstDate, lastDate);

  debugger;

  _.forEach(activeEmployees, employee => {
    debugger;

    let dayBlock = {};

    _.forEach(_days, day => {

      let dayFormatted = dateFormatter(day);

      let sortedActivities = _.sortBy(visitStatus, ["Date", "Start time", "End time"], "asc");
      debugger;

      _.forEach(sortedActivities, activity => {
        debugger;

        let _rep = _.find(representatives, { "ID": activity["Representative ID"] });

        if (_rep && _rep != null) {
          activity["Paychex ID"] = _.trim(_rep["Paychex ID"])
        }

      });

      let employeesDay = _.filter(sortedActivities, a => {

        let _rep = _.find(representatives, { "ID": a["Representative ID"] });

        let representativeId = _.trim(a["Paychex ID"] || a["Representative ID"]);

        let _formattedDate = dateFormatter(new Date(a["Scheduled date"]));

        if (representativeId == employee["EE ID"] && _formattedDate == dayFormatted) {
          return true;
        }
      }
      );
      debugger;

      if (employeesDay != null && employeesDay.length > 0) {
        debugger;

        let employeesDDCheckIns = _.filter(employeesDay, s => _.startsWith(s["Place ID"], "DD-"));

        if ((employeesDDCheckIns != null && employeesDDCheckIns.length > 0)) {
          debugger;
          let firstCheckinAtScheduledDD = new Date(_.last(employeesDDCheckIns)["Start time"]);
          let lastCheckinAtScheduledDD = new Date(_.first(employeesDDCheckIns)["End time"] || _.last(employeesDDCheckIns)["Start time"]);

          const diff = lastCheckinAtScheduledDD - firstCheckinAtScheduledDD;

          var hours = dateDiffToString(firstCheckinAtScheduledDD, lastCheckinAtScheduledDD);

          dayBlock[dayFormatted + " - IN"] = timeFormatter(firstCheckinAtScheduledDD);
          dayBlock[dayFormatted + " - OUT"] = timeFormatter(lastCheckinAtScheduledDD);
          dayBlock[dayFormatted + " - Total"] = hours;

          dayBlock[dayFormatted + " - Place Visit"] = employeesDay.length > 2 ? employeesDay.length - 2 : 0;
        }
      }
    })
    debugger;

    let firstBlock = {
      "EE ID": employee["EE ID"],
      "Full Name": employee["Full Name"],
      "Position": employee["Position"],
      "Location": employee["Location"],
      "Territory": employee["Territory"],
      "Total Hrs": ""
    };


    let payrollChartRow = { ...firstBlock, ...dayBlock };

    //debugger;
    let hours = [];
    for (const [key, value] of Object.entries(dayBlock)) {
      if (key.includes("- Total")) {

        if (value && value != NaN) {
          hours.push(value);
        }
      }
    }
    if (hours && hours.length > 0) {
      payrollChartRow["Total Hrs"] = sumTime(hours);
    }

    payrollChart.push(payrollChartRow)
  });






  let sortedPayrollChart = _.sortBy(payrollChart, ["Territory", "Location", "Position", "Full Name"]);

  console.log("sortedPayrollChart", sortedPayrollChart);
  downloadExcel(sortedPayrollChart, "Payroll");

}

function App() {
  const [allowAnyDD, setAllowAnyDD] = useState(true);

  return (
    <div className="App">
      <div className="app-container">

        <div>
          <label htmlFor="upload"> <a target="_blank" href="https://user.repsly.com/manage/export" >Visits with Place Data</a></label><br />
          <input type="file" name="input-representatives" id="input-representatives" onChange={
            e => {
              readUploadFile(e).then(value => {
                placeVisits = value;
              })
            }
          }
          />
        </div>

        <div>
          <label htmlFor="upload"> <a target="_blank" href="https://user.repsly.com/manage/export" >Visit Status</a></label><br />
          <input type="file" disabled="true" name="input-representatives" id="input-representatives" onChange={
            e => {
              readUploadFile(e).then(value => {
                visitStatus = value;
              })
            }
          }
          />
        </div>

        <div>
          <label htmlFor="upload">Active Employees</label><br />
          <input type="file" name="input-representatives" id="input-representatives" onChange={
            e => {
              readUploadFile(e).then(value => {
                activeEmployees = value;
              })
            }
          }
          />
        </div>
        <div>
          <label htmlFor="upload"> <a target="_blank" href="https://user.repsly.com/users">Repsly Users</a></label><br />
          <input type="file" name="input-representatives" id="input-representatives" onChange={
            e => {
              readUploadFile(e).then(value => {
                representatives = value;
              })
            }
          }
          />
        </div>
        <div>
          <hr />
          <button onClick={() => { prepareChart(allowAnyDD); }}>Prepare by any DD or Office</button>
          <br />
          <br />
          <button disabled="true" onClick={() => { prepareChartBySchedules(); }}>Prepare by scheduled DD only</button>
        </div>
      </div>
    </div>
  );
}

export default App;
